import output from './output.json';
import rickyAndTif from './ricky-and-tif.json';
const getData = () => {
    const backgroundPhotos = Object.values(output.photos).filter(({ isBackgroundPhoto }) => isBackgroundPhoto);
    return {
        photos: output.photos,
        galleries: output.galleries,
        backgroundPhotos,
        privateGalleries: {
            'ricky-and-tif': rickyAndTif
        }
    };
};
export default getData;
